// extracted by mini-css-extract-plugin
export var background = "sidebar-module--background--OgkV+";
export var badge = "sidebar-module--badge--wGah2";
export var badgeGray = "sidebar-module--badgeGray--8-v2Q";
export var boxContent = "sidebar-module--boxContent--k+wos";
export var boxName = "sidebar-module--boxName--xeZxA";
export var contactBlock = "sidebar-module--contactBlock--KcPbv";
export var contactBlockItem = "sidebar-module--contactBlockItem--whWe3";
export var content = "sidebar-module--content--NC1Ik";
export var emailHider = "sidebar-module--emailHider---rV60";
export var name = "sidebar-module--name--o3IP0";
export var profileAvatar = "sidebar-module--profileAvatar--jBeOx";
export var resumeDownload = "sidebar-module--resumeDownload--k4g2+";
export var sideBar404Radius = "sidebar-module--sideBar404Radius--bW-gt";
export var sidebarContent = "sidebar-module--sidebarContent--ZD-7M";